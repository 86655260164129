import React from "react";
import {
    createBrowserRouter,
    RouterProvider,
    Navigate
  } from "react-router-dom";
import SignInSide from "./views/sign-in-side/SignInSide";
import CurriculumVitaePage from "./views/dashboard/pages/CurriculumVitaePage";
import MotivationPage from "./views/dashboard/pages/MotivationPage";
import OffrePage from "./views/dashboard/pages/OffrePage";
import VersusPage from "./views/dashboard/pages/VersusPage";
import SimulatorPage from "./views/dashboard/pages/SimulatorPage";
import GenerateMotivationPage from "./views/dashboard/pages/GenerateMotivationPage";
import Dashboard from "./views/dashboard/Dashboard";
import { ToastContainer } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
// styles
import 'react-toastify/dist/ReactToastify.min.css';
import "./App.css";
import MainGrid from './views/dashboard/pages/MainGrid';
// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import { StateProvider } from "./state/StateProvider";
import { mainInitialState, mainReducer } from "./state/MainReducer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getLocalStorageItem, setLocalStorageItem } from "./utils/contentData";

import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'

const CLIENT_ID = "680935081627-4fo5md58cd48dt56afn7okjcqladgdnu.apps.googleusercontent.com"

initFontAwesome();

const queryClient = new QueryClient()

const App = () => {

    const router = createBrowserRouter([
        {
            path: "/login",
            element:  <SignInSide  />,
        },
        {
            path: "",
            element: <Navigate to="/dashboard" replace />,
        },
        {
            path: "/dashboard",
            element: <Dashboard />,
            children: [
                {
                    path: "",
                    element: <MainGrid />
                },
                {
                  path: "cv",
                  element: <CurriculumVitaePage />,
                },
                {
                  path: "motivation",
                  element: <MotivationPage />,
                },
                {
                    path: "offres",
                    element: <OffrePage />,
                },
                {
                    path: "cv-vs-offre",
                    element: <VersusPage />,
                },
                {
                    path: "generateur-motivation",
                    element: <GenerateMotivationPage />,
                },
                {
                    path: "simulateur",
                    element: <SimulatorPage />,
                }
            ]
        }
    ]);

    window.moment.locale('fr')

    if (!getLocalStorageItem('user')) {
        let user_ano = uuidv4()
        setLocalStorageItem('raw_user', {'credential': user_ano})
        setLocalStorageItem('user', {'name': 'Anonyme #'+user_ano.slice(0, 5), 'svg': true})
        setLocalStorageItem('user_type', 'anonymous')
    }

    return (
        <div class="parent">
            <div class="child">
                <QueryClientProvider client={queryClient}>
                    <GoogleOAuthProvider clientId={CLIENT_ID}>
                        <StateProvider initialState={mainInitialState} reducer={mainReducer}>
                            <div id="app" className="d-flex flex-column h-100">
                                <div className="flex-grow-1 mt-5">
                                    <RouterProvider router={router} />
                                </div>
                                <ToastContainer />
                            </div>
                        </StateProvider>
                    </GoogleOAuthProvider>
                </QueryClientProvider>
            </div>
        </div>
    );
};

export default App;
