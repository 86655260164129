import { useState, useEffect, useRef } from 'react';
import { Box, Paper, List, ListItem, ListItemText, Container, Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Import Grid2
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import useFetch from "../../../api/api_call";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { getLocalStorageItem, setLocalStorageItem } from "../../../utils/contentData";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import Dictaphone from '../components/Dictaphone'
import Speaker from '../components/Speaker'
import MessageInput from '../components/MessageInput'
import AudioTextInput from '../components/AudioTextInput'


const mockMessages = [
  {
    id: 0,
    role: 'assistant',
    content: "Bonjour, je suis Georges, le recruteur d'une entreprise petrolière. Bienvenue à l'entretien pour le poste des relations international. Pouvez-vous vous presenter succintement ?",
    text: "Bonjour, je suis Georges, le recruteur d'une entreprise petrolière. Bienvenue à l'entretien pour le poste des relations international. Pouvez-vous vous presenter succintement ?"
  }
];

function SimulatorPage() {
  const queryClient = useQueryClient()
  const navigate = useNavigate();
  const bottomRef = useRef(null);
  const [user, setUser] = useState(getLocalStorageItem('user'));
  const [messages, setMessages] = useState(mockMessages);
  const [agentTalking, setAgentTalking] = useState(false);

  const { continueDiscussion } = useFetch()

  function useContinueDiscussion() {
    return useMutation({
      mutationFn: (args) => continueDiscussion(...args),
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: ['continueDiscussion']});
      },
    });
  }

  const createContinueDiscussionMutation = useContinueDiscussion();

  window.addEventListener("storage", function (e) {
    setUser(getLocalStorageItem('user'));
  });

  const scrollToBottom = () => {
    if (bottomRef.current) {
      if (typeof bottomRef.current.scrollIntoViewIfNeeded === 'function') {
        bottomRef.current.scrollIntoViewIfNeeded({ behavior: 'smooth' });
      } else {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const UserMessage = styled('div', { shouldForwardProp: (prop) => prop !== 'audio' })(({ theme, audio }) => ({
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1, 2),
    paddingRight: audio ? theme.spacing(6) : theme.spacing(2),
    borderRadius: '1rem',
    borderTopRightRadius: 0,
    alignSelf: 'flex-end',
    maxWidth: '80%',
    wordWrap: 'break-word',
  }));

  const AgentMessage = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 2),
    borderRadius: '1rem',
    borderTopLeftRadius: 0,
    alignSelf: 'flex-end',
    maxWidth: '80%',
    wordWrap: 'break-word',
  }));

  const MessageWrapper = styled('div')(({ theme, align }) => ({
    display: 'flex',
    marginBottom: theme.spacing(1),
    justifyContent: align === 'user' ? 'flex-end' : 'flex-start',
  }));

  useEffect(() => {
    if (user.svg) {
      navigate('/login');
    }
  }, [user]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  let last_message = messages.slice(-1)[0]
  console.log('last_message', last_message)
  console.log(last_message.role === 'assistant' ? last_message.content : '')

  return (
    <Box
      sx={{
        width: '100%',
        mx: 'auto',
        mt: 4,
      }}
    >
      <Typography component="h2" variant="h6" sx={{ mb: 4 }}>
        Simulation d'entretient
      </Typography>

      <Divider />
      <Grid container spacing={3} sx={{height: '78vh'}}>
        <Grid sx={{height: '100%'}}>
          <Speaker text={last_message.role === 'assistant' ? last_message.content : ''}  setAgentTalking={setAgentTalking}/>
        </Grid>
        <Grid size="grow">
            <div style={{display: 'flex', height: '100%', justifyContent: 'space-between', flexDirection: 'column'}}>
              <Container>
                <Box sx={{ width: '100%', mt: 4}}>
                  <Paper elevation={2} sx={{ padding: 2, overflow: 'auto', maxHeight: '60vh', minHeight: '60vh' }}>
                    <List>
                      {messages.map((message, index) => (
                        <ListItem key={index} sx={{ padding: 0 }}>
                          <ListItemText
                            sx={{ margin: 0 }}
                            primary={
                              <MessageWrapper align={message.role}>
                                {message.role === 'user' ? (
                                  <>
                                    <UserMessage audio={message.audio}>
                                      {message.text}
                                      {message.audio && (
                                        <IconButton
                                          size="small"
                                          sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: 8,
                                            transform: 'translateY(-50%)',
                                          }}
                                          onClick={() => message.audio.play()}
                                        >
                                          <VolumeUpIcon fontSize="small" />
                                        </IconButton>
                                      )}
                                    </UserMessage>
                                  </>
                                ) : (
                                  <AgentMessage>{message.text}</AgentMessage>
                                )}
                              </MessageWrapper>
                            }
                          />
                        </ListItem>
                      ))}
                      <div ref={bottomRef} />
                    </List>
                  </Paper>
                </Box>
              </Container>
              <Container sx={{alignSelf:'end'}}>
                <AudioTextInput agentTalking={agentTalking} messages={messages} setMessages={setMessages} createContinueDiscussionMutation={createContinueDiscussionMutation}/>
              </Container>
            </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SimulatorPage;
