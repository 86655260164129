import { toast } from 'react-toastify';
import { useState } from 'react';
import { getLocalStorageItem, setLocalStorageItem } from "../utils/contentData";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import {parseJwt} from '../utils/contentData'


const useFetch = () => {
  let raw_user = getLocalStorageItem('raw_user')
  let user_type = getLocalStorageItem('user_type')

  const fetchData = async (url, method, body, authenticated, options = {}) => {
    try {
      let token = raw_user?.credential
      if (user_type === 'anonymous'){
        options.headers = {}
        options.headers["User-Type"] = `anonymous`
        options.headers["Authorization"] = `Bearer ${token}`
        options.headers["Content-type"] = `application/json`
      } else {
        if (!token) {
          window.location.href = '/login';
          return
        }
        if (!options.headers) {
          options.headers = { 'Content-type': 'application/json' }
        }
        options.headers["Authorization"] = `Bearer ${token}`
      }
      options.method = method
      if (method !== "GET") {
        options.body = JSON.stringify(body)
      }
      const res = await fetch(url, options)
      const json = await res.json()
      if (res.status > 499) {
        toast.error("An error occured. We are working on it", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000
        });
      }
      if (res.status === 401) {
        toast.error("Not authenticated ", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000
        });
        setLocalStorageItem('user', null)
        setLocalStorageItem('raw_user', null)
        googleLogout();
        window.location.href = '/login';
      }
      if (res.status === 403) {
        toast.error("Not authorized", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000
        });
      }
      if (res.status > 399) {
        toast.error("Something went wrong. please try again !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000
        });
      }
      return { 'json': json, 'status': res.status }
    } catch (error) {
      console.error(error)
      return { 'error': error }
    }
  }

  // USERS

  const fetchPageContent = async (pageUrl) => {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/ml/fetch-page-content?url=${encodeURIComponent(pageUrl)}`
    return await fetchData(apiUrl, 'GET', {}, true, {})
  }

  const getCurrentUser = async () => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/users/me', 'GET', {}, false, {})
  }

  const patchCurrentUser = async (patch) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/users/patch', 'POST', patch, true, {})
  }

  const postComment = async (comment) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/users/comment', 'POST', {comment: comment}, true, {})
  }

  // CV

  const getListOfUserCv = async () => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/cv/list', 'GET', {}, false, {})
  }

  const archiveCv = async (cv_id) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + "/api/v1/cv/archived/" + cv_id, 'GET', {}, false, {})
  }

  const getListOfMlCv = async () => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/ml/list/cv', 'GET', {}, false, {})
  }

  const createMasterCv = async (OcrValue, pdfPages) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/cv/create', 'POST', { 'master':  true, 'ocr': OcrValue, 'pdfPages': pdfPages }, true, {})
  }
  
  const setMasterCv = async (cv_id) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/cv/set/master/' + cv_id, 'GET', { }, true, {})
  }

  const createCv = async (OcrValue, pdfPages) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/cv/create', 'POST', { 'master':  false, 'ocr': OcrValue, 'pdfPages': pdfPages }, false, {})
  }

  const startCvAnalyse = async (cv_id) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/ml/analyse/' + cv_id, 'GET', {}, false, {})
  }

  const getCv = async (cv_id) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/cv/get/' + cv_id, 'GET', {}, false, {})
  }

  // OFFRE

  const getListOfUserOffers = async () => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/list', 'GET', {}, false, {})
  }

  const createOffer = async (offer, pdfPages) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/create', 'POST', {'ocr': offer, 'pdfPages': pdfPages}, false, {})
  }

  const getOffer = async (offer_id) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/get/' + offer_id, 'GET', {}, false, {})
  }

  const startOfferAnalyse = async (offer_id) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/ml/analyse/offer/' + offer_id, 'GET', {}, false, {})
  }

  const getListOfMlOffer = async () => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/ml/list/offer', 'GET', {}, false, {})
  }

  const archiveOffer = async (offer_id) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + "/api/v1/offers/archived/" + offer_id, 'GET', {}, false, {})
  }


  // Motivation

  const getListOfUserMotivation = async () => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/motivation/list', 'GET', {}, false, {})
  }

  const createMotivation = async (motivation, pdfPages) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/motivation/create', 'POST', {'ocr': motivation, 'pdfPages': pdfPages}, false, {})
  }

  const startMotivationAnalyse = async (motivation_id) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/ml/analyse/motivation/' + motivation_id, 'GET', {}, false, {})
  }

  const getListOfMlMotivation = async () => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/ml/list/motivation', 'GET', {}, false, {})
  }

  const archiveMotivation = async (motivation_id) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + "/api/v1/motivation/archived/" + motivation_id, 'GET', {}, false, {})
  }

  const generateCoverLetter = async (cv_id, offer_id) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/ml/analyse/motivation/generate/' + cv_id + '/' + offer_id, 'GET', {}, false, {})
  }

  // CV vs OFFRE

  const startDeepCvVsOfferAnalyse = async (cv_id, offer_id) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/ml/analyse/cvVSoffer/'+cv_id+'/'+offer_id+'/deep', 'GET', {}, true, {})
  }

  const getListOfMlCandidature = async () => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/ml/list/candidature', 'GET', {}, true, {})
  }


  // OTHERS

  const startGenericAnalyse = async (cv_id, offer_id, structure, prompt) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/ml/analyse/generic', 'POST', {cv_id: cv_id, offer_id: offer_id, structure: structure, prompt: prompt}, true, {})
  }

  const continueDiscussion = async (payload) => {
    return await fetchData(process.env.REACT_APP_BACKEND_URL + '/api/v1/ml/simulation/continue', 'POST', {discussion: payload}, true, {})
  }

  return { fetchData, continueDiscussion, generateCoverLetter, getListOfMlCandidature, startDeepCvVsOfferAnalyse, getListOfUserMotivation, createMotivation, startMotivationAnalyse, getListOfMlMotivation, archiveMotivation, startGenericAnalyse, patchCurrentUser, getOffer, getCv, getCurrentUser, getListOfUserCv, getListOfUserOffers, createMasterCv, startCvAnalyse, createCv, setMasterCv, createOffer, startOfferAnalyse, postComment, fetchPageContent, getListOfMlCv, archiveCv, archiveOffer, getListOfMlOffer }
}

export default useFetch
