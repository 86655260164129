import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Define styles for the PDF document
const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
    textDecoration: 'underline',
  },
  recipientSection: {
    marginBottom: 20,
  },
  recipientText: {
    fontSize: 12,
    marginBottom: 5,
  },
  contactInfo: {
    fontSize: 12,
    marginBottom: 10,
    position: 'absolute',
    top: 40,
    left: 40,
  },
  date: {
    fontSize: 12,
    marginBottom: 20,
    position: 'absolute',
    top: 40,
    right: 40,
  },
  objet: {
    fontSize: 14,
    marginTop: 100,      // Extra space at the top to fill the page
    marginBottom: 20,   // Less space at the bottom
    textAlign: 'left', // Align text to the left
    fontWeight: 'bold',
  },
  body: {
    fontSize: 12,
    marginTop: 10,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  paragraph: {
    marginBottom: 10, // space between paragraphs
  },
  closing: {
    fontSize: 12,
    marginTop: 30,
    textAlign: 'left',
  },
  signature: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 10,
  },
});

// Define the PDF document component
const CoverLetter = ({ data }) => (
  <Document>
    <Page style={styles.page}>
      {/* Candidate's Contact Information */}
      <View style={styles.contactInfo}>
        <Text>{data.prenom_nom_candidat}</Text>
        <Text>{data.adresse}</Text>
        <Text>{data.telephone}</Text>
        <Text>{data.email}</Text>
      </View>

      {/* Date */}
      <View>
        <Text style={styles.date}>{new Date().toLocaleDateString()}</Text>
      </View>

      {/* Objet */}
      <View>
        <Text style={styles.objet}>{data.objet}</Text>
      </View>

      {/* Body Paragraphs */}
      <View style={styles.body}>
        <Text style={styles.paragraph}>{data.premier_paragraphe_introduction}</Text>
        <Text style={styles.paragraph}>{data.second_paragraphe_parcours_competence}</Text>
        <Text style={styles.paragraph}>{data.troisieme_paragraphe_lien_profil_poste}</Text>
        <Text style={styles.paragraph}>{data.quatrieme_paragraphe_conclusion}</Text>
      </View>

      {/* Closing */}
      <View style={styles.closing}>
        <Text>Cordialement,</Text>
        <Text style={styles.signature}>{data.prenom_nom_candidat}</Text>
      </View>
    </Page>
  </Document>
);

export default CoverLetter;
