import React, { useState, useEffect } from 'react';
import { Box, IconButton, TextField } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SendIcon from "@mui/icons-material/Send";
import { keyframes, styled } from '@mui/system';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const ThinkingBubbleStyled = styled(MoreHorizIcon)`
  animation: ${pulse} 1.2s ease-in-out infinite;
`;

const ThinkingBubble = () => {
    return <ThinkingBubbleStyled sx={{ marginBottom: '-5px' }} />;
};

const AudioTextInput = ({ messages, setMessages, createContinueDiscussionMutation, agentTalking }) => {
    const [message, setMessage] = useState("");
    const [userClickOnVoice, setUserClickOnVoice] = useState(false);
    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    useEffect(() => {
        setMessage(transcript);
    }, [transcript]);

    useEffect(() => {
        if (userClickOnVoice) {
            resetTranscript()
            if (agentTalking){
                SpeechRecognition.stopListening();
            } else {
                SpeechRecognition.startListening();
            }
            setMessage(transcript);
        }
    }, [agentTalking]);

    const addMessage = (newMessageText, user) => {
        const newMessage = {
          id: new Date().getTime(),
          role: user,
          text: newMessageText,
          content: newMessageText,
        };
        let new_msg =  [...messages, newMessage]
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        return new_msg
    };

    const addThinkingBubbleMessage = (newMessageText) => {
        const newMessage = {
          id: new Date().getTime(),
          role: 'assistant',
          text: <ThinkingBubble />,
          content: 'thinking',
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      };

    const removeThinking = (newMessageText) => {
        setMessages((prevMessages) => {
          return prevMessages.filter((message) => message.content !== "thinking");
        });
      }

    const handleSendMessage = async () => {
        if (message.trim() !== "") {

            let new_discussion = addMessage(message, 'user')
            // Ajouter ThinkingBubble
            addThinkingBubbleMessage()
            setMessage("");
            resetTranscript();
            
            new_discussion = new_discussion.filter((message) => message.content !== "thinking")
            let resultat =  await createContinueDiscussionMutation.mutateAsync([new_discussion])
            addMessage(resultat.json.ml.content, 'assistant')

            removeThinking()
        }
    };

    const handleStartListening = () => {
        if (!listening) {
            setUserClickOnVoice(true)
            SpeechRecognition.startListening({ language: 'fr', continuous: true });
        } else {
            setUserClickOnVoice(false)
            SpeechRecognition.stopListening();
        }
    };

    if (!browserSupportsSpeechRecognition) {
        return <p>Votre navigateur ne supporte pas la reconnaissance vocale.</p>;
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
            <TextField
                variant="outlined"
                fullWidth
                multiline
                minRows={7}
                maxRows={7}
                placeholder="Votre réponse"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === "Enter") handleSendMessage();
                }}
            />
            <IconButton
                color={listening ? "secondary" : "primary"}
                onClick={handleStartListening}
            >
                {listening ? <MicOffIcon /> : <MicIcon />}
            </IconButton>
            <IconButton
                color="primary"
                onClick={() => handleSendMessage()}
                disabled={message.trim() === ""}
            >
                <SendIcon />
            </IconButton>
        </Box>
    );
};

export default AudioTextInput;
