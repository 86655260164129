import React, { useState, useEffect } from "react";
import { Box, Button, Slider, Typography, Card, CardMedia, CircularProgress, IconButton } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SettingsIcon from "@mui/icons-material/Settings";
import minion from "../../../assets/minion.png";

const Speaker = (content) => {
  const [start, setStart] = useState(false);
  const [prevContent, setPrevContent]  = useState('');
  const [utterance, setUtterance] = useState(null);
  const [pitch, setPitch] = useState(0.7);
  const [rate, setRate] = useState(0.9);
  const [volume, setVolume] = useState(0.8);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(false);

  useEffect(()=> {
    content.setAgentTalking(isPlaying)
  }, [isPlaying, content])


  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(content.text);
    const voices = synth.getVoices();
    const frenchVoice = voices.find((voice) => voice.lang.startsWith("fr")) || voices[0];

    u.voice = frenchVoice;
    u.onstart = () => setIsPlaying(true);
    u.onend = () => setIsPlaying(false);

    setUtterance(u);
    if (prevContent) {
      if (content.text !== 'thinking')
      synth.speak(u);
    }
    setPrevContent(content.text)

    return () => {
      synth.cancel();
    };
  }, [content.text]);

  const handleReplay = () => {
    if (utterance) {
      const synth = window.speechSynthesis;
      utterance.pitch = pitch;
      utterance.rate = rate;
      utterance.volume = volume;

      synth.cancel();
      synth.speak(utterance);
    }
  };

  const handleStart = () => {
    setStart(true)
    if (utterance) {
      const synth = window.speechSynthesis;
      utterance.pitch = pitch;
      utterance.rate = rate;
      utterance.volume = volume;

      synth.cancel();
      synth.speak(utterance);
    }
  };

  const handleToggleControls = () => {
    setShowControls((prev) => !prev);
  };

  return (
    <Box sx={{ p: 3, maxWidth: 400, mx: "auto", textAlign: "center" }}>
      {/* Image or animation */}
      <Card sx={{height: 300, width: 300, position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {isPlaying ? (
          <CircularProgress size={60} />
        ) : (
          <CardMedia
            component="img"
            alt="Personne"
            image={minion}
          />
        )}
        {/* Toggle Button */}
        <IconButton
          onClick={handleToggleControls}
          sx={{ position: "absolute", top: 10, right: 10, backgroundColor: "rgba(0,0,0,0.5)", color: "white" }}
        >
          <SettingsIcon />
        </IconButton>
      </Card>

      {/* Controls */}
      {showControls && (
        <Box>
          <Typography gutterBottom>Hauteur (Pitch)</Typography>
          <Slider
            value={pitch}
            min={0.5}
            max={2}
            step={0.1}
            onChange={(e, value) => setPitch(value)}
            aria-labelledby="pitch-slider"
          />

          <Typography gutterBottom>Vitesse (Speed)</Typography>
          <Slider
            value={rate}
            min={0.5}
            max={2}
            step={0.1}
            onChange={(e, value) => setRate(value)}
            aria-labelledby="rate-slider"
          />

          <Typography gutterBottom>Volume</Typography>
          <Slider
            value={volume}
            min={0}
            max={1}
            step={0.1}
            onChange={(e, value) => setVolume(value)}
            aria-labelledby="volume-slider"
          />
        </Box>
      )}

      {/* Replay Button */}
      { start && <Box sx={{ mt: 3 }}>
        <Button variant="contained" onClick={handleReplay} startIcon={<ReplayIcon />}>
          Ré-écouter
        </Button>
      </Box>
      }
      {!start && <Box sx={{ mt: 3 }}>
        <Button variant="contained" onClick={handleStart} startIcon={<PlayArrowIcon />}>
          Débuter l'entretien
        </Button>
        </Box>
      }
    </Box>
  );
};

export default Speaker;
