import {
    Button,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem, 
    ListItemText,
    Paper,
    Switch,
    Box,
    TextField, // Add to imports
    Typography
} from "@mui/material";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
    keyframes, // Add this import
    styled
} from '@mui/system';
import SendIcon from "@mui/icons-material/Send";

const pulse = keyframes`
0% {
  transform: scale(1);
  opacity: 1;
}
50% {
  transform: scale(1.5);
  opacity: 0.7;
}
100% {
  transform: scale(1);
  opacity: 1;
}`;



const ThinkingBubbleStyled = styled(MoreHorizIcon)`
  animation: ${pulse} 1.2s ease-in-out infinite;
  margin-bottom: -5px;
`;

const ThinkingBubble = () => {
    return <ThinkingBubbleStyled sx={{ marginBottom: '-5px' }} />;
  };


const MessageInput = ({message, setMessage, messages, setMessages, resetTranscript}) => {
    const handleInputChange = (event) => {
        setMessage(event.target.value);
      };

      function filterMessageObjects(list) {
        return list.map(({ role, content }) => ({ role, content }));
      }

      const handleSendMessage = async () => {
        resetTranscript()
        if (message.trim() !== "") {}
          // Send the message to the chat
      
          // Add the new message to the chat area
          setMessages((prevMessages) => [
            ...prevMessages,
            { role: "user", content: message, text: message, audio: null },
          ]);
      
          // Clear the input field
          setMessage("");
      
          // Add thinking bubble
          setMessages((prevMessages) => [
            ...prevMessages,
            { role: "assistant", content: <ThinkingBubble sx={{ marginBottom: '-5px' }} />, text: <ThinkingBubble  sx={{ marginBottom: '-5px' }} />, key: "thinking" },
          ]);
      
          // Create backend chat input
          let messageObjects = filterMessageObjects(messages)
          messageObjects.push({ role: "user", content: message })
      
          // Create endpoint for just getting the completion
          try {
            // Send the text message to the backend
      
            // Remove the thinking bubble
            setMessages((prevMessages) => {
              return prevMessages.filter((message) => message.key !== "thinking");
            });
      
          } catch (error) {
            console.error("Error sending text message:", error);
            alert(error);
          }
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
          handleSendMessage();
        }
      };


      return (
        <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            placeholder="Votre réponse"
            value={message}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <IconButton
            color="primary"
            onClick={() => handleSendMessage()}
            disabled={message.trim() === ""}
          >
            <SendIcon />
          </IconButton>
        </Box>
      );
};

export default MessageInput