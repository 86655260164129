// DownloadCoverLetter.js
import React, { useEffect } from 'react';
import { PDFDownloadLink, PDFViewer, BlobProvider } from '@react-pdf/renderer';
import DownloadIcon from '@mui/icons-material/Download';
import CoverLetter from './CoverLetter';
import {
  Button,
} from "@mui/material";

const DownloadCoverLetter = ({data}) => {

  return (
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      <BlobProvider document={<CoverLetter data={data} />}>
        {({ blob, url, loading, error }) => {
          if (loading) return <div>Chargement PDF...</div>;

          // Function to trigger download
          const downloadPdf = () => {
            const link = document.createElement('a');
            link.href = url;
            link.download = 'lettre_motivation.pdf';
            link.click();
          };

          return (
            <Button
              variant={"contained"}
              onClick={() => downloadPdf()}
              startIcon={<DownloadIcon sx={{ fontSize: 40 }} />}
            >
              Télécharger
            </Button>
          );
        }}
      </BlobProvider>
      <PDFViewer height={410} showToolbar={false}>
        <CoverLetter data={data} />
      </PDFViewer>
    </div>
  );
};

export default DownloadCoverLetter;
