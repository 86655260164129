import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Divider,
  CircularProgress,
  Button,
  Skeleton,
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import DownloadCoverLetter from '../generator/DownloadCoverLetter'
import Grid from '@mui/material/Grid2';
import useFetch from "../../../api/api_call";
import RocketIcon from '@mui/icons-material/Rocket';
import {fromDataIdToAnalyse} from  './utils'
import { getColorFromRange } from "../../../utils/contentData"
import { useNavigate } from "react-router-dom";
import { getLocalStorageItem } from "../../../utils/contentData";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const CustomStyledDatagrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell:focus': {
    outline: ' none'
    }
}));

const CustomSlider = styled(CircularProgress)(({ theme, custom_color }) => ({
  color: custom_color,
}));

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CustomSlider variant="determinate" {...props}  custom_color={getColorFromRange(Math.round(props.value))} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary" }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}


const GenerateMotivationPage = () => {
  const navigate = useNavigate();

  const [selectedCv, setSelectedCV] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [lettre, setLettre] = useState(null);

  const [loadAnalyse, setLoadAnalyse] = useState(false);

  const { getListOfUserCv, getListOfMlCv, getListOfUserOffers, getListOfMlOffer, generateCoverLetter } = useFetch()

  const { isLoading: isPendingUserCv, data: userCv  } = useQuery({ queryKey: ['getListOfUserCv'], queryFn: getListOfUserCv })
  const { isLoading: isFetchingMlCv, data: mlsCv } = useQuery({ queryKey: ['getListOfMlCv'], queryFn: getListOfMlCv })

  const { isLoading: isPendingUserOffer, data: userOffer  } = useQuery({ queryKey: ['getListOfUserOffers'], queryFn: getListOfUserOffers })
  const { isLoading: isFetchingMlOffer, data: mlsOffer } = useQuery({ queryKey: ['getListOfMlOffer'], queryFn: getListOfMlOffer })

  const [user, setUser] = useState(getLocalStorageItem('user'));

  function useGenerateCoverLetter() {
    return useMutation({
      mutationFn: (args) => generateCoverLetter(...args),
      onSuccess: () => {

      },
    });
  }
  const generateCoverLetterMutation = useGenerateCoverLetter();

  window.addEventListener("storage",(function(e){
      setUser(getLocalStorageItem('user'));
  }).bind(this));

  useEffect(() =>  {
    if (user.svg){
      navigate('/login')
    }
  }, [user])

  const handleApiCall = async () => {
    console.log(selectedCv)
    setLoadAnalyse(true);
    //  make await api call
    let lettre = await generateCoverLetterMutation.mutateAsync([selectedCv.cv_id, selectedOffer.offer_id])
    setLettre(lettre.json.ml.content)
    console.log('ANALYSE RETURN')
    setLoadAnalyse(false);
  }

  function CustomNoRowsOverlay() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent:  'center', height: '100%'}}>
        {isPendingUserCv || isPendingUserOffer && <CircularProgress size={40} />}
        {!isPendingUserCv && !isPendingUserOffer && <Typography variant="h6" color="textSecondary" style={{textAlign: 'center'}}>
          Pas de donnée
        </Typography>
        }
      </div>
    );
  }

  const columnsCV = [
    {
      field: 'preview',
      headerAlign: 'center',
      headerName: 'Aperçu',
      renderCell: (params) => (
        <div style={{display: 'flex', justifyContent: 'center', height: '100px', width: '100%'}}>
          <img
            src={params.row.pdfPages[0]}
            style={{ height: '100%' }}
          />
        </div>
      ),
      width: 100,
    },
    {
      field: 'score',
      headerAlign: 'center',
      headerName: 'Score (%)',
      width: 125,
      renderCell: (params) => {
        let val = fromDataIdToAnalyse(params.row?.cv_id, 'cv_id', mlsCv?.json.mls)
        return (<div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          {(isFetchingMlCv || !val.content) && <span style={{width: '100%'}}><Skeleton /></span>}
          {(!isFetchingMlCv && val.content) && <CircularProgressWithLabel
            value={val.content?.score}
          />}
        </div>
        )
      },
    },
  ];

  const columnsOffer = [
    {
      field: 'preview',
      headerAlign: 'center',
      headerName: 'Aperçu',
      renderCell: (params) => (
        <div style={{display: 'flex', justifyContent: 'center', height: '100px', width: '100%'}}>
          <img
            src={params.row.pdfPages[0]}
            style={{ height: '100%' }}
          />
        </div>
      ),
      width: 100,
    },
    {
      field: 'score',
      headerAlign: 'center',
      headerName: 'Score (%)',
      width: 125,
      renderCell: (params) => {
        let val = fromDataIdToAnalyse(params.row?.offer_id, 'offer_id', mlsOffer?.json.mls)
        return (<div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          {(isFetchingMlOffer || !val.content) && <span style={{width: '100%'}}><Skeleton /></span>}
          {(!isFetchingMlOffer && val.content) && <CircularProgressWithLabel
            value={val.content?.score}
          />}
        </div>
        )
      },
    },
  ];

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' }, mx: 'auto', mt: 4}}>
      <Typography component="h2" variant="h6" sx={{ mb: 4 }}>
        Generer une lettre de motivation
      </Typography>

      <Divider />

      <Grid container justifyContent="center" spacing={2} style={{paddingTop: 100, justifyContent: 'space-evenly'}}>
        {/* Section gauche : Upload de fichier */}
        <Grid sx={{width: '30%', height: '70%'}} display="flex"  textAlign='center' justifyContent="center">
            <CustomStyledDatagrid
              disableColumnFilter={true}
              disableColumnSorting={true}
              rows={userCv?.json?.cvs || []}
              columns={columnsCV}
              hideFooter={true}
              onRowClick={(params) => {setSelectedCV(params.row)}}
              getRowId={(row) => row._id.$oid}
              hideFooterPagination
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              rowHeight={125}
            />
        </Grid>

        {/* Icône de séparation ⚔️ */}
        <Grid  display="flex" flexDirection="column" justifyContent="center" alignItems="center" fontSize={'3rem'}>
          
          <Box display="flex" justifyContent="center" mt={4}>
            <Button
              variant={((!selectedCv || !selectedOffer) || loadAnalyse || lettre) ? "outlined" : "contained"}
              onClick={() => handleApiCall()}
              disabled={(!selectedCv || !selectedOffer) || loadAnalyse}
              startIcon={loadAnalyse ? <CircularProgress size={24} /> : <RocketIcon sx={{ fontSize: 40 }} />}
            >
              Générer votre lettre de motivation
            </Button>
          </Box>
        </Grid>

        <Grid sx={{width: '30%', height: '70%'}} display="flex" textAlign='center' justifyContent="center">
            <CustomStyledDatagrid
              disableColumnFilter={true}
              disableColumnSorting={true}
              rows={userOffer?.json?.offers || []}
              columns={columnsOffer}
              hideFooter={true}
              onRowClick={(params) => {setSelectedOffer(params.row)}}
              getRowId={(row) => row._id.$oid}
              hideFooterPagination
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              rowHeight={125}
            />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{paddingTop: '24px'}}>
        <Box>
          {lettre && <div style={{height: '1000px'}}>
            <DownloadCoverLetter data={lettre} />
          </div>
          }
        </Box>
      </Grid>
    </Box>
  );
};

export default GenerateMotivationPage;
