
export const fromDataIdToAnalyse = (id_to_find, id_label, mls) => {
    if (!mls) {
      return {}
    }
    for (let i = 0; i < mls.length; i++) {
      if (mls[i][id_label] == id_to_find) {
        return mls[i]
      }
    }
    return {}
}