import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Tabs,
  Tab,
  TextField,
  Skeleton,
  Divider,
  CircularProgress,
  Chip,
  Button,
  Alert,
  Paper
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { resizeBase64Image } from '../../../utils/contentData'
import { styled, keyframes } from '@mui/system';
import Tesseract from 'tesseract.js';
import Grid from '@mui/material/Grid2'; // Use '@mui/material/Unstable_Grid2' for Grid2
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { FilePreviewerThumbnail } from '../../../components/react-file-previewer/src/index';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import useFetch from "../../../api/api_call";
import { getColorFromRange } from "../../../utils/contentData"
import { pdfjs } from 'react-pdf'
import { Radar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { RadialLinearScale, Chart, RadarController,LineElement, PointElement, Filler } from 'chart.js';
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`

// Define the wiggle animation
const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(1deg); }
  20% { transform: rotate(-1deg); }
  25% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

// Styled component with conditional animation
const FileContainer = styled(Box)(({ theme, animate }) => ({
  width: '100%',
  minHeight: '10%',
  padding: '40px',
  textAlign: 'center',
  justifyContent: 'center',
  border: '3px dashed #91917d',
  cursor: 'pointer',
  borderRadius: '10px',
  backgroundColor: '#f9f9f9',
  transition: 'all 0.3s ease',
  // Apply infinite wiggle animation if animate prop is true
  animation: animate ? `${wiggle} 5s infinite ease` : 'none',
  '&:hover': {
    backgroundColor: '#3490db',
    borderColor: '#3490db',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transform: 'scale(1.05)',
  },
}));


const CustomStyledDatagrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell:focus': {
    outline: ' none'
    }
}));

Chart.register(RadialLinearScale, RadarController,LineElement, PointElement, Filler);


const RadarData = (score) => ({
  labels: [
    "Generales",
    "Structure",
    "Experience",
    "Formation",
    "Competences",
    "Projets",
    "Personnalisation"
  ],
  datasets: [{
    label: 'Force / Faiblesse',
    data: [65, 59, 90, 81, 56, 55, 40],
    fill: true,
    backgroundColor: getColorFromRange(Math.round(score), 0.2),
    borderColor: getColorFromRange(Math.round(score)),
    pointBackgroundColor: getColorFromRange(Math.round(score)),
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgb(255, 99, 132)'
  }]
});


const CustomSlider = styled(CircularProgress)(({ theme, custom_color }) => ({
  color: custom_color,
}));


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CustomSlider variant="determinate" {...props}  custom_color={getColorFromRange(Math.round(props.value))} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary" }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const OffrePage = () => {
  const queryClient = useQueryClient()

  const inputFileOffre = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loaderOffreUploadMsg, setLoaderOffreUploadMsg] = useState(null);

  const [loadingRows, setLoadingRows] = useState({});


  const { createOffer, getListOfUserOffers, startOfferAnalyse, getListOfMlOffer, archiveOffer } = useFetch()

  const { isLoading: isPendingUserOffer, data: userOffer  } = useQuery({ queryKey: ['getListOfUserOffers'], queryFn: getListOfUserOffers })
  const { isFetching: isFetchingMlOffer, data: mlsOffer } = useQuery({ queryKey: ['getListOfMlOffer'], queryFn: getListOfMlOffer })

  function useCreateOffer() {
    return useMutation({
      mutationFn: (args) => createOffer(...args),
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: ['getListOfUserOffers']});
      },
    });
  }

  function useStartOfferAnalyse() {
    return useMutation({
      mutationFn: (args) => startOfferAnalyse(...args),
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: ['getListOfMlOffer']});
      },
    });
  }

  function useArchiveOffer() {
    return useMutation({
      mutationFn: (args) => archiveOffer(...args),
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: ['getListOfUserOffers']});
      },
    });
  }

  const createOfferMutation = useCreateOffer();
  const startOfferAnalyseMutation = useStartOfferAnalyse();
  const archiveOfferMutation = useArchiveOffer();

  useEffect(() => {
    if (!selectedRow && userOffer?.json?.offers?.length > 0) {
      setSelectedRow(userOffer.json.offers[0])
    }
  }, [userOffer])

  const setLoading = (rowId, loadingType, isLoading) => {
    setLoadingRows((prev) => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [loadingType]: isLoading,
      },
    }));
  };


  const handleDeleteClick = async (rowId, offer_id) => {
    setLoading(rowId, 'process', false);
    setLoading(rowId, 'delete', true);
    // Simulate delete action
    await archiveOfferMutation.mutateAsync([offer_id])
    setLoading(rowId, 'delete', false);
    setSelectedRow(null)
  };


  function CustomNoRowsOverlay() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        {isPendingUserOffer && <CircularProgress size={40} />}
        {!isPendingUserOffer && <Typography variant="h6" color="textSecondary" style={{textAlign: 'center'}}>
          Téléchargez votre offre d'emploi pour débuter !
        </Typography>
        }
      </div>
    );
  }

  const recognizeText = async (pages) => {
    let txt = ""
    let new_pages = []
    if (pages.length > 0) {
        for (let pageNum = 0; pageNum < pages.length; pageNum++) {
            const val = await Tesseract.recognize(pages[pageNum]);
            txt += val.data.text
            new_pages.push(await processImage(pages[pageNum]))
        }
        setLoaderOffreUploadMsg("Analyse des informations")
        let offer = await createOfferMutation.mutateAsync([txt, new_pages])
        setLoaderOffreUploadMsg("Finalisation")
        await startOfferAnalyseMutation.mutateAsync([offer.json.offer.offer_id])
        setLoaderOffreUploadMsg(null)
    }
  }

  async function processImage(base64Str) {
    // Calculate size in bytes and convert to MB
    const sizeInBytes = (base64Str.length * 3) / 4;
    const sizeInMB = sizeInBytes / (1024 * 1024);

    if (sizeInMB > 10) {
        // Set error if file is over 10MB
        toast.error("Fichier trop lourd, supérieur au maximum de 10MB", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000
        });
        return null;
    } else if (sizeInMB > 1) {
        // Resize if file is over 1MB but under 10MB
        const resizedBase64 = await resizeBase64Image(base64Str, 800, 800);
        return resizedBase64;
    }

    // If file is under 1MB, no resizing needed
    return base64Str;
}

  const onOffreFileChange = (event) => {
    setLoaderOffreUploadMsg("Chargement du fichier")
    const file = event.target.files[0];

    if (file && file.type === "application/pdf") {
      console.log("This is a PDF file.");
      const reader = new FileReader();
        reader.onloadend = function() {
            const arrayBuffer = reader.result;
            pdfjs.getDocument(new Uint8Array(arrayBuffer)).promise.then(function(pdf) {
                setLoaderOffreUploadMsg("Lecture du fichier")
                const pages = [];
                let pagePromises = [];
                
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    pagePromises.push(
                        pdf.getPage(pageNum).then(function(page) {
                            const viewport = page.getViewport({ scale: 5 });
                            const canvas = document.createElement('canvas');
                            const context = canvas.getContext('2d');
                            canvas.width = viewport.width;
                            canvas.height = viewport.height;

                            const renderContext = {
                                canvasContext: context,
                                viewport: viewport,
                            };

                            return page.render(renderContext).promise.then(function() {
                                pages.push(canvas.toDataURL('image/png'));
                            });
                        })
                    );
                }
                Promise.all(pagePromises).then(async () => {
                  setLoaderOffreUploadMsg("Extraction des informations")
                  recognizeText(pages);
                });
            });
        };
        reader.readAsArrayBuffer(file);
    } else {
        console.log("This is an image file (JPG/PNG).");
        const reader = new FileReader();
        reader.onloadend = function() {
            const imageDataUrl = reader.result; // Base64 image data URL
            setLoaderOffreUploadMsg("Chargement de l'image");

            // Process image (similar to how PDF pages are processed)
            const images = [imageDataUrl];
            setLoaderOffreUploadMsg("Extraction des informations");

            // You can call the recognizeText() function on the image data
            recognizeText(images);
        };
        reader.readAsDataURL(file); // Read image file as a Data URL (Base64)
    }
  };

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
  };

  const fromIdToAnalyse = (offer_id) => {
    let mls = mlsOffer?.json?.mls
    if (!mls) {
      return {}
    }
    for (let i = 0; i < mls.length; i++) {
      if (mls[i]['offer_id'] == offer_id) {
        return mls[i]
      }
    }
    return {}
  }

  // Function to get the explications
  function getExplicationsBelowMax(data) {
    const explications = [];
    const content = data.content;
    const maxValues = content.max;

    // Iterate over each section in content
    for (const section in content) {
      // Ignore non-section keys like "max", "nuage_de_mot_clef", and "score"
      if (section !== "max" && section !== "nuage_de_mot_clef" && section !== "score") {
          let sectionData = content[section];
          explications.push({'data': sectionData.explication, 'level': sectionData?.explication_niveau_severite || 'warning'});
      }
    }

    return explications;
  }

  const fromidToExplanations = (offer_id) => {
    let mls = mlsOffer?.json?.mls
    if (!mls) {
      return []
    }
    for (let i = 0; i < mls.length; i++) {
      if (mls[i]['offer_id'] === offer_id) {
        return getExplicationsBelowMax(mls[i])
      }
    }
    return []
  }

  const fromLabelToProgress = (label) => {
    if (label === "Chargement du fichier") {
      return 25
    }
    if (label === 'Lecture du fichier') {
      return 50
    }
    if (label === 'Extraction des informations') {
      return 60
    }
    if (label === 'Analyse des informations') {
      return 80
    }
    if (label === 'Finalisation') {
      return 95
    }
  }


  const columns = [
    {
      field: 'preview',
      headerAlign: 'center',
      headerName: 'Aperçu',
      renderCell: (params) => (
        <div style={{display: 'flex', justifyContent: 'center', height: '100px', width: '100%'}}>
          <img
            src={params.row.pdfPages[0]}
            style={{ height: '100%' }}
          />
        </div>
      ),
      width: 100,
    },
    {
      field: 'score',
      headerAlign: 'center',
      headerName: 'Score (%)',
      width: 125,
      renderCell: (params) => {
        let val = fromIdToAnalyse(params.row?.offer_id)

        return (<div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          {(isFetchingMlOffer || !val.content) && <span style={{width: '100%'}}><Skeleton /></span>}
          {(!isFetchingMlOffer && val.content) && <CircularProgressWithLabel
            value={val.content?.score}
          />}
        </div>
        )
        },
    },
    {
      field: 'actions',
      headerAlign: 'center',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => {
        const rowId = params.row.offer_id; // Assuming each row has a unique 'id'
        const isProcessing = loadingRows[rowId]?.process;
        const isDeleting = loadingRows[rowId]?.delete;
  
        return (
          <div style={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
            <IconButton
              onClick={() => handleDeleteClick(rowId, rowId)}
              disabled={isProcessing || isDeleting}
            >
              {isDeleting ? <CircularProgress size={24} /> : <DeleteIcon />}
            </IconButton>
          </div>
        );
      },
    },
  ];

  let selected_ml = {}
  if (selectedRow?.offer_id) {
    selected_ml = fromIdToAnalyse(selectedRow?.offer_id)
  }

  let first_load_no_data = isPendingUserOffer || userOffer?.json?.offers.length === 0

  const getRadarData = (ml_data) => {
    let r_data = JSON.parse(JSON.stringify(RadarData(ml_data.content.score)))
    let max = ml_data.content.max
    let data_points = []
    let labels = []
    for (const [key, value] of Object.entries(ml_data.content)) {
      if (key !== 'max' &&  key !== 'score' &&  key !== 'nuage_de_mot_clef' &&  key !== 'point_fort') {
        labels.push(key.toUpperCase())
        let tot =  0
        for (const [key_a, value_a] of Object.entries(value)) {
          if(typeof value_a === 'number')  {
            tot += value_a
          }
        }
        data_points.push(tot / max[key] * 100)
      }
    }
    r_data.labels = labels
    r_data.datasets[0].data = data_points
    return r_data
  }

  return (
    <Box
      sx={{
        width: '100%',
        mx: 'auto',
        mt: 4,
      }}
    >
      <Typography component="h2" variant="h6" sx={{ mb: 4 }}>
        Vos Offres d'emplois
      </Typography>

      <Divider />

      <Grid container spacing={2}>
      <Grid item xs={12} md={3} sx={{width: { xs: '100%', md: first_load_no_data ? '100%' : '25%'}}}>
        {loaderOffreUploadMsg && <Box style={{alignSelf: 'center', textAlign: 'center', padding: 20, minHeight: '10%',}}>
          <i>{loaderOffreUploadMsg}</i>
              <LinearProgress variant="determinate" value={fromLabelToProgress(loaderOffreUploadMsg)} />
          </Box>
        }
        {!loaderOffreUploadMsg && <Box style={{padding: 20}}>
            <FileContainer
              animate={(userOffer?.json?.offers?.length === 0).toString()}
              role="button"
              aria-label="Upload your file"
              onClick={() => {
                inputFileOffre.current.click();
              }}
              style={{padding: 24}}
            >
              <Typography variant="body1">Téléchargez votre Offre</Typography>
              <i>(pdf, jpg, png)</i>
              <input
                type="file"
                ref={inputFileOffre}
                style={{ display: 'none' }}
                accept=".pdf, .png, .jpg, .jpeg"
                onChange={onOffreFileChange}
              />
            </FileContainer>
          </Box>
        }
          <Box sx={{ width: '100%', height: '70vh', alignSelf: 'end'}}>
            <CustomStyledDatagrid
              disableColumnFilter={true}
              disableColumnSorting={true}
              rows={userOffer?.json?.offers || []}
              columns={columns}
              hideFooter={true}
              onRowClick={handleRowClick}
              getRowId={(row) => row._id.$oid}
              hideFooterPagination
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              rowHeight={125}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9} sx={{width: { xs: '100%', md: '70%'}}}>
          <Box sx={{ flexDirection: 'row', display: 'flex', paddingTop: '20px' }}> 
            <Box sx={{textAlign: 'center', height: '25vh', flex: 1 }}>
              {selected_ml?.content && <Radar
              options={{
                maintainAspectRatio: false,
                aspectRatio: 1,
                layout: {
                  padding: {
                    left: 10
                  }
                },
                scale: {
                  min: 0,
                  max: 100,
              },
              }}
              data={getRadarData(selected_ml)}
            /> }
            </Box>
            <Box sx={{textAlign: 'center', flex: 1, justifyContent: 'center', alignContent: 'center'}}>
              <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center', width: '100%'}}>
                {isFetchingMlOffer && <span style={{width: '100%'}}><Skeleton /></span>}
                {!isFetchingMlOffer && selected_ml?.content?.nuage_de_mot_clef?.tag_mot_clefs?.map((tag) => (
                    <Chip key={tag} size="small" label={tag} variant="outlined" color="chipViolet" />
                ))}
              </div>
            </Box>
          </Box>

          <Box sx={{width: '100%', paddingTop: '50px', textAlign: 'center',  height: '30vh'}}>
            {fromidToExplanations(selectedRow?.offer_id).map((explanation) => (
              explanation.data && <Alert key={explanation.data} sx={{marginBottom: 2}} severity={explanation.level}>{explanation.data}</Alert>
            ))}
          </Box>
          </Grid>
        </Grid>
    </Box>
  );
};

export default OffrePage;
