import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Tabs,
  Tab,
  TextField,
  Skeleton,
  Divider,
  CircularProgress,
  Chip,
  Button,
  Alert,
  Paper
} from '@mui/material';
import { toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';
import { styled, keyframes } from '@mui/system';
import Tesseract from 'tesseract.js';
import Grid from '@mui/material/Grid2'; // Use '@mui/material/Unstable_Grid2' for Grid2
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { FilePreviewerThumbnail } from '../../../components/react-file-previewer/src/index';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import useFetch from "../../../api/api_call";
import { getColorFromRange } from "../../../utils/contentData"
import { pdfjs } from 'react-pdf'
import { Radar } from 'react-chartjs-2';
import { resizeBase64Image } from '../../../utils/contentData'
import { RadialLinearScale, Chart, RadarController,LineElement, PointElement, Filler } from 'chart.js';
import { ConsoleLine } from 'mdi-material-ui';
import { min } from 'lodash';
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`



const CustomStyledDatagrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell:focus': {
    outline: ' none'
    }
}));

Chart.register(RadialLinearScale, RadarController,LineElement, PointElement, Filler);

const RadarData = (score) => ({
  labels: [
    "Generales",
    "Structure",
    "Experience",
    "Formation",
    "Competences",
    "Projets",
    "Personnalisation"
  ],
  datasets: [{
    label: 'Force / Faiblesse',
    data: [65, 59, 90, 81, 56, 55, 40],
    fill: true,
    backgroundColor: getColorFromRange(Math.round(score), 0.2),
    borderColor: getColorFromRange(Math.round(score)),
    pointBackgroundColor: getColorFromRange(Math.round(score)),
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgb(255, 99, 132)'
  }]
});


const CustomSlider = styled(CircularProgress)(({ theme, custom_color }) => ({
  color: custom_color,
}));



function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CustomSlider variant="determinate" {...props}  custom_color={getColorFromRange(Math.round(props.value))} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary" }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}


const fromIdToOffer = (offer_id, offers) => {
  if (offers) {
    for (let i = 0; i < offers.length; i++) {
      if (offers[i].offer_id === offer_id) {
        return offers[i]
      }
    }
  }
  return {}
}

const fromIdToCv = (cv_id, cvs) => {
  if (cvs) {
    for (let i = 0; i < cvs.length; i++) {
      if (cvs[i].cv_id === cv_id) {
        return cvs[i]
      }
    }
  }
  return {}
}


const VersusPageDetail = ({cvs, offers}) => {
  const [selectedRow, setSelectedRow] = useState({});
  const [mlAnalyse, setMlAnalyse] = useState([]);

  const [loadList, setLoadList] = useState(false);
  const [loadingRows, setLoadingRows] = useState({});

  const { getListOfMlCandidature, archiveCandidature } = useFetch()

  const setLoading = (rowId, loadingType, isLoading) => {
    setLoadingRows((prev) => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [loadingType]: isLoading,
      },
    }));
  };

  function CustomNoRowsOverlay() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        {loadList && <CircularProgress size={40} />}
        {!loadList && <Typography variant="h6" color="textSecondary">
          Comparer votre CV a une offre d'emploi
        </Typography>
        }
      </div>
    );
  }

  const resetPageData = async () =>  {
    let valueML = await getListOfMlCandidature()
    setMlAnalyse(valueML.json.mls)
  }

  const handleDeleteClick = async (rowId, candidature_id) => {
    setLoading(rowId, 'process', false);
    setLoading(rowId, 'delete', true);
    // Simulate delete action
    await archiveCandidature(candidature_id)
    await resetPageData()
    setLoading(rowId, 'delete', false);
    setSelectedRow(null)
  };

  useEffect(() => {
    let ignore = false
    const fetchData = async () => {
      setLoadList(true)
      let valueML = await getListOfMlCandidature()
      if(!ignore){
        setMlAnalyse(valueML.json.mls)
        if (valueML.json.mls.length > 0) {
          setSelectedRow(valueML.json.mls[0]);
        }
        setLoadList(false)
      }
    }
    fetchData()
    return () => {ignore=true}
  }, [])

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
  };

  const sumValues = obj => Object.values(obj).reduce((a, b) => a + b, 0);

  // Function to get the explications
  function getExplicationsBelowMax(data) {
    const explications = [];
    const content = data.content;

    // Iterate over each section in content
    console.log('content', content)
    
    for (const section in content) {
      // Ignore non-section keys like "max", "nuage_de_mot_clef", and "score"
      if (section !== "max" && section !== "nuage_de_mot_clef" && section !== "score" && section !== "detail_score") {
        if (content['detail_score'][section] < 80) {
          explications.push(content[section].explication);
        }
      }
    }

  return explications;
}

  const fromCandidatureidToExplanations = (candidature_id) => {
    if (!mlAnalyse) {
      return []
    }
    for (let i = 0; i < mlAnalyse.length; i++) {
      if (mlAnalyse[i]['candidature_id'] === candidature_id) {
        return getExplicationsBelowMax(mlAnalyse[i])
      }
    }
    return []
  }

  const columns = [
    {
      field: 'preview',
      headerAlign: 'center',
      headerName: 'Aperçu',
      renderCell: (params) => (
        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', height: '100px', width: '100%'}}>
          <img
            src={fromIdToCv(params.row.cv_id, cvs)?.pdfPages}
            style={{ height: '100%' }}
          />
          <span style={{alignSelf: 'center', paddingRight: 10, paddingLeft: 10}}>⚔️</span>
          <img
            src={fromIdToOffer(params.row.offer_id, offers)?.pdfPages}
            style={{ height: '100%' }}
          />
        </div>
      ),
      width: 400,
    },
    {
      field: 'score',
      headerAlign: 'center',
      headerName: 'Score (%)',
      flex: 1,
      renderCell: (params) => (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <CircularProgressWithLabel
            value={params.row?.content?.score}
          />
        </div>
      ),
    },
    {
      field: 'actions',
      headerAlign: 'center',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => {
        console.log('params', params)
        const rowId = params.row.candidature_id; // Assuming each row has a unique 'id'
        const isProcessing = loadingRows[rowId]?.process;
        const isDeleting = loadingRows[rowId]?.delete;
  
        return (
          <div style={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
            <IconButton
              onClick={() => handleDeleteClick(rowId, rowId)}
              disabled={isProcessing || isDeleting}
            >
              {isDeleting ? <CircularProgress size={24} /> : <DeleteIcon />}
            </IconButton>
          </div>
        );
      },
    },
  ];

  const getRadarData = (ml_data) => {
    let r_data = JSON.parse(JSON.stringify(RadarData(ml_data.content.score)))
    let data_points = []
    let labels = []
    for (const [key, value] of Object.entries(ml_data.content)) {
      if (key !== 'max' &&  key !== 'score' &&  key !== 'nuage_de_mot_clef' &&  key !== 'point_fort' &&  key !== 'detail_score') {
        labels.push(key)
        data_points.push(ml_data.content['detail_score'][key])
      }
    }
    r_data.labels = labels
    r_data.datasets[0].data = data_points
    console.log(r_data)
    return r_data
  }
  
  let selected_ml = mlAnalyse

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: { sm: '100%', md: '1700px' },
        mx: 'auto',
        mt: 4,
      }}
    >
      <Typography component="h2" variant="h6" sx={{ mb: 4 }}>
        Vos candidatures
      </Typography>

      <Grid container spacing={2} height={'100%'} sx={{flex: 1, alignContent: 'end'}}>
        <Grid size={8} sx={{flex: 1}} height={'100%'}>

          <Box sx={{ width: '100%', height: '100vh', alignSelf: 'end'}}>
            <CustomStyledDatagrid
              disableColumnFilter={true}
              disableColumnSorting={true}
              rows={mlAnalyse}
              columns={columns}
              hideFooter={true}
              onRowClick={handleRowClick}
              getRowId={(row) => row._id.$oid}
              hideFooterPagination
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              rowHeight={125}
            />
          </Box>
        </Grid>
        <Grid size={4}>
            <Box sx={{width: '100%', textAlign: 'center',  height: '30vh'}}>
              {selectedRow.content && <Radar
              options={{
                maintainAspectRatio: false,
                aspectRatio: 1,
                layout: {
                  padding: {
                    left: 10
                  }
                },
                scale: {
                  min: 0,
                  max: 100,
              },
              }}
              data={getRadarData(selectedRow)}
            /> }
            </Box>
            <Box sx={{width: '100%', paddingTop: '50px', textAlign: 'center',  height: '30vh'}}>
              {fromCandidatureidToExplanations(selectedRow?.candidature_id).map((explanation) => (
                <Alert key={explanation} sx={{marginBottom: 2}} severity="warning">{explanation}</Alert>
              ))}
            </Box>
          </Grid>
        </Grid>
    </Box>
  );
};

export default VersusPageDetail;
