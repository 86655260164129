import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function PublicityCard() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card variant="outlined" sx={{ m: 1.5, p: 1.5 }}>
        <amp-ad width="100vw" height="320"
          type="adsense"
          data-ad-client="ca-pub-2735720238134520"
          data-ad-slot="5451892445"
          data-auto-format="rspv"
          data-full-width="">
            <div overflow=""></div>
        </amp-ad>
    </Card>
  );
}
